import { get, post } from '@/utils/requestWrapper';
import type { GetScansPayload, Scan } from '@/types/inventory';

const URL = {
    SUPPLY_SCAN: '/pos/supply-scan',
    SUPPLY_SCAN_DETAIL: '/pos/supply-scan/:id',
};

function getRecentScans(params: GetScansPayload) {
    return get<Scan[]>({
        url: URL.SUPPLY_SCAN,
        params,
    });
}

function createScan(body: Pick<Scan, 'orderNumber'>) {
    return post<Scan>({
        url: URL.SUPPLY_SCAN,
        body,
    });
}

export default {
    getRecentScans,
    createScan,
};
