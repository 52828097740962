import type { PropType, ComponentOptions } from 'vue';

export type VueComponentType = PropType<ComponentOptions|ComponentOptions['setup']>;

export interface Address {
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    street: string;
    number: string;
    bus?: string;
    postcode: string;
    city: string;
    countryCode: string;
    company?: string;
    vatNumber?: string;
}

export interface Country {
    code: string;
    name: string;
    provinces: never[];
}

export interface Image {
    id: number;
    owner: string;
    path: string;
    type: string;
    file: string;
}

export interface PosUnit {
    id: string;
    name: string;
    store: Store;
}

export interface Store {
    id: string;
    name: string;
    address: Address;
    email: string;
}

export interface PaymentMethod {
    id: string;
    code: PaymentMethodCode;
    name: string;
    description?: string;
    instructions?: string;
    position: number;
}

export interface Translation {
    id: number;
    locale: string;
    name: string;
    translatable: string;
    createdAt: string;
    updatedAt: string;
}

// only add the ones that should be allowed to be used in the POS
export const paymentMethodCodes = [
    'cash',
    'voucher',
    'other',
    'offline_terminal',
] as const;
export type PaymentMethodCode = typeof paymentMethodCodes[number];
export function isPaymentMethodCode(value: string): value is PaymentMethodCode {
    return paymentMethodCodes.includes(value as PaymentMethodCode);
}

export interface Voucher {
    id: number;
    code: string;
    title: string;
    amount: number;
    restAmount: number;
    enabled: boolean;
    used: boolean;
    currencyCode: string;
    expiresAt: Date | string;
}

export interface Notification {
    label: string;
    visualisation: string;
}
