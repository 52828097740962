import type { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './routeNames';
import LoginView from '@/views/Login/LoginView.vue';
import MenuView from '@/views/MenuView.vue';
import { getCustomerDataIfNeeded } from './utils';
import { useNewSaleStore } from '@/stores';
import { storeToRefs } from 'pinia';

export const Routes: RouteRecordRaw[] = [
    {
        name: RouteNames.Menu,
        path: '/',
        component: MenuView,
        meta: {
            requiresAuth: true,
            layout: {
                width: 'medium',
                disableMenu: true,
            },
        },
    },
    {
        name: RouteNames.Test,
        path: '/test',
        component: () => import('../views/TestView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: RouteNames.New_Sale,
        path: '/new-sale',
        redirect: { name: RouteNames.New_Sale_Cart },
        children: [
            {
                name: RouteNames.New_Sale_Cart,
                path: 'cart',
                component: () => import('../views/NewSale/CartView.vue'),
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        name: RouteNames.New_Sale_Cart_Select_List,
                        path: 'select-list/:id',
                        components: {
                            slideOutPanel: () => import('../views/NewSale/Cart/SelectWishlist/SelectWishlistView.vue'),
                        },
                        props: true,
                        meta: {
                            slideOutPanel: {
                                width: '114rem',
                            },
                        },
                    },
                    {
                        name: RouteNames.New_Sale_Cart_Select_Customer,
                        path: 'select-client',
                        components: {
                            slideOutPanel: () => import('../views/NewSale/Cart/SelectCustomer/SelectCustomerView.vue'),
                        },
                        meta: {
                            slideOutPanel: {
                                width: '114rem',
                            },
                        },
                    },
                    {
                        name: RouteNames.New_Sale_Cart_Customer_New,
                        path: 'select-client/new',
                        components: {
                            slideOutPanel: () => import('../views/NewSale/Cart/SelectCustomer/NewCustomerView.vue'),
                        },
                        meta: {
                            slideOutPanel: {
                                width: '132rem',
                            },
                        },
                    },
                    {
                        name: RouteNames.New_Sale_Cart_Customer_Detail,
                        path: 'select-client/:customerId/:addressId?',
                        components: {
                            slideOutPanel: () => import('../views/NewSale/Cart/SelectCustomer/CustomerDetailsView.vue'),
                        },
                        props: true,
                        meta: {
                            slideOutPanel: {
                                width: '132rem',
                            },
                        },
                        beforeEnter: (to, from, next) => {
                            getCustomerDataIfNeeded({
                                customerId: to.params.customerId.toString(),
                                ...(to.params.addressId && { addressId: to.params.addressId.toString() }),
                            }, next);
                        },
                    },
                    {
                        name: RouteNames.New_Sale_Cart_Customer_Detail_Orders,
                        path: 'select-client/:customerId/orders',
                        components: {
                            slideOutPanel: () =>
                                import('../views/NewSale/Cart/SelectCustomer/CustomerOrderHistoryView.vue'),
                        },
                        props: true,
                        meta: {
                            slideOutPanel: {
                                width: '132rem',
                            },
                        },
                        beforeEnter: (to, from, next) => {
                            getCustomerDataIfNeeded({
                                customerId: to.params.customerId.toString(),
                            }, next);
                        },
                    },
                    {
                        name: RouteNames.New_Sale_Cart_Customer_Detail_New_Address,
                        path: 'select-client/:customerId/address',
                        components: {
                            slideOutPanel: () => import('../views/NewSale/Cart/SelectCustomer/NewAddressView.vue'),
                        },
                        props: true,
                        meta: {
                            slideOutPanel: {
                                width: '132rem',
                            },
                        },
                        beforeEnter: (to, from, next) => {
                            getCustomerDataIfNeeded({
                                customerId: to.params.customerId.toString(),
                            }, next);
                        },
                    },
                ],
            },
            {
                name: RouteNames.New_Sale_Checkout,
                path: '/new-sale/checkout',
                component: () => import('../views/NewSale/CheckoutView.vue'),
                beforeEnter: (to, from, next) => {
                    const newSaleStore = useNewSaleStore();
                    const { order } = storeToRefs(newSaleStore);

                    if (order.value.data?.tokenValue) {
                        next();
                    } else {
                        next({ name: RouteNames.New_Sale_Cart });
                    }
                },
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        name: RouteNames.Overview_Sales,
        path: '/orders',
        component: () => import('../views/Orders/OrdersOverviewView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: RouteNames.Invoices,
        path: '/invoices',
        component: () => import('../views/Invoices/InvoicesOverviewView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: RouteNames.Inventory_Import,
        path: '/inventory-import',
        component: () => import('../views/InventoryImport/InventoryImportView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: RouteNames.Login,
        path: '/login',
        component: LoginView,
        meta: {
            anonymousOnly: true,
            layout: {
                hideNavBar: true,
                width: 'small',
            },
        },
    },
    {
        name: RouteNames.NotFound,
        path: '/:pathMatch(.*)*',
        component: () => import('../views/NotFoundView.vue'),
    },
];
