import { defineStore, storeToRefs } from 'pinia';
import { type AsyncEntity, AsyncStatus, type WithContext } from '@/types/api';
import type { Order, PosSalesStat } from '@/types/order';
import { type Invoice, InvoiceType } from '@/types/invoice';
import { reactive } from 'vue';
import { asyncFetchHandler } from '@/utils/asyncEntity';
import OrderService from '@/services/OrderService';
import InvoiceService from '@/services/InvoiceService';
import { useI18n } from 'vue-i18n';
import { useAuthStore, useCustomerStore } from '@/stores';
import type { AddAddressPayload } from '@/types/customer';
import { getEndOfDay, getStartOfDay } from '@/utils/date';

export const useOrdersStore = defineStore('orders', () => {
    const { d } = useI18n();
    const customerStore = useCustomerStore();
    const authStore = useAuthStore();
    const { storeDetail } = storeToRefs(authStore);

    const ordersEntity: AsyncEntity<Order[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const invoicesEntity: AsyncEntity<WithContext<Invoice>> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const dailyInvoicesEntity: AsyncEntity<WithContext<Invoice>> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const invoiceDetailPdf: AsyncEntity<{ blob: Blob, height?: string }> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const posSalesStatsEntity: AsyncEntity<PosSalesStat[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    function getInvoices({ page = 1, itemsPerPage, issuedAt, posUnitId, storeId, type }: {
        page: number;
        itemsPerPage: number;
        issuedAt?: { start: Date | string; end: Date | string };
        posUnitId?: string;
        storeId?: string;
        type?: string | string[];
    }) {
        let filterDate;
        if (issuedAt) {
            filterDate = {
                after: getStartOfDay(issuedAt.start),
                before: getEndOfDay(issuedAt.end),
            };
        }

        return asyncFetchHandler({
            asyncEntity: invoicesEntity,
            service: InvoiceService.getInvoices,
        })({
            page,
            itemsPerPage,
            ...(filterDate && {
                'issuedAt[after]': d(filterDate.after, 'long'),
                'issuedAt[before]': d(filterDate.before, 'long'),
            }),
            'order.posUnit': posUnitId,
            'order.posUnit.store': storeId,
            type,
        });
    }

    function getDailyInvoices({ issuedAt }: {
        issuedAt?: { start: Date | string; end: Date | string };
    }) {
        let filterDate;
        if (issuedAt) {
            filterDate = {
                after: getStartOfDay(issuedAt.start),
                before: getEndOfDay(issuedAt.end),
            };
        }

        return asyncFetchHandler({
            asyncEntity: dailyInvoicesEntity,
            service: InvoiceService.getInvoices,
        })({
            page: 1,
            itemsPerPage: 20,
            ...(filterDate && {
                'issuedAt[after]': d(filterDate.after, 'long'),
                'issuedAt[before]': d(filterDate.before, 'long'),
            }),
            type: InvoiceType.DailyInvoice,
        });
    }

    function getOrderInvoice(invoiceId: string) {
        return asyncFetchHandler({
            asyncEntity: invoiceDetailPdf,
            service: InvoiceService.getInvoicePdf,
        })({ id: invoiceId });
    }

    function getOrderReceipt(receiptId: string) {
        return asyncFetchHandler({
            asyncEntity: invoiceDetailPdf,
            service: InvoiceService.getReceiptPdf,
        })({ id: receiptId });
    }

    function createAddressAndConvertReceiptToInvoice({ address, receiptId }: {
        receiptId: string;
        address: AddAddressPayload;
    }) {
        return customerStore.addNewCustomerAddress(address)
            .then((newAddress) => {
                if (newAddress instanceof Error) {
                    return newAddress;
                }

                return InvoiceService.convertReceiptToInvoice({
                    addressId: newAddress.id,
                    receiptId,
                });
            });
    }

    function getPosSalesStats({ date }: { date: Date | string}) {
        const beforeDate = getEndOfDay(date);
        const afterDate = getStartOfDay(date);
        const storeId = storeDetail.value.data?.id;

        return asyncFetchHandler({
            asyncEntity: posSalesStatsEntity,
            service: OrderService.getPosSalesStats,
        })({
            'date[before]': d(beforeDate, 'long'),
            'date[after]': d(afterDate, 'long'),
            store: storeId,
        });
    }

    return {
        ordersEntity,
        invoicesEntity, getInvoices,
        dailyInvoicesEntity, getDailyInvoices,
        invoiceDetailPdf, getOrderInvoice, getOrderReceipt,
        createAddressAndConvertReceiptToInvoice,
        posSalesStatsEntity, getPosSalesStats,
    };
}, {
    persist: false,
});
