import type { ApiSearchParams } from '@/types/api';

export enum InvoiceType {
    Unknown = '',
    Invoice = 'invoice',
    Receipt = 'receipt',
    DailyInvoice = 'daily_invoice',
}

export interface Invoice {
    id: string;
    issuedAt: string;
    number: string;
    order?: string;
    total: number;
    currencyCode: string;
    type: InvoiceType;
}

export interface GetInvoicesPayload extends Omit<ApiSearchParams, 'query'> {
    'issuedAt[after]'?: string;
    'issuedAt[strictly_after]'?: string;
    'issuedAt[before]'?: string;
    'issuedAt[strictly_before]'?: string;
    'order.posUnit'?: string | string[];
    'order.posUnit.store'?: string | string[];
    'order.customer'?: string;
    'type'?: string | string[];
}
