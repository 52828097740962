
import { AsyncStatus, type AsyncEntity } from '@/types/api';
import { defineStore } from 'pinia';
import { reactive } from 'vue';
import { asyncFetchHandler } from '@/utils/asyncEntity';
import InventoryService from '@/services/InventoryService';
import type { Scan } from '@/types/inventory';

export const useInventoryStore = defineStore('inventory', () => {
    const recentScans: AsyncEntity<Scan[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const newScan: AsyncEntity<Scan> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    function getRecentScans() {
        return asyncFetchHandler({
            asyncEntity: recentScans,
            service: InventoryService.getRecentScans,
        })({
            page: 1,
            itemsPerPage: 10,
        });
    }

    function createScan(orderNumber: string) {
        return asyncFetchHandler({
            asyncEntity: newScan,
            service: InventoryService.createScan,
        })({ orderNumber });
    }

    return {
        recentScans, getRecentScans,
        newScan, createScan,
    };
}, {
    persist: false,
});
